<template>
    <v-row class="ma-0 pa-0" justify="center" :style="outerstyle">
        <v-col cols="12" class="ma-0 pa-0" :style="middleStyle">
            <v-row :style="innerStyle" justify="center">
                <slot></slot>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        backgroundColor: {
            type: String,
            default: null, // example '#000000'
        },
        maxWidth: {
            type: String,
            default: '1200px',
        },
    },
    computed: {
        outerstyle() {
            return this.backgroundColor ? `background-color: ${this.backgroundColor};` : '';
        },
        middleStyle() {
            return `max-width: ${this.maxWidth};`;
        },
        innerStyle() {
            let hpad = '4px';
            let vpad = '4px';
            if (this.$vuetify.breakpoint.mdAndUp) {
                hpad = '20px';
                vpad = '20px';
            }

            return `width: 100%; margin: 0px; padding-top: ${vpad}; padding-bottom: ${vpad}; padding-left: ${hpad}; padding-right: ${hpad};`;
        },
    },
};
</script>
